
import data from "@/mixins/data.js"

import TabsButtons from "./TabsButtons";
import StepTab1 from "./StepTab1";
import StepTab2 from "./StepTab2";
import StepTab3 from "./StepTab3";
import StepTab4 from "./StepTab4";

export default {
  name: "TabsSection",
  data() {
    return {
      currentStep: 1,
    }
  },

  methods: {
    changeTab(data) {
      this.currentStep = data
    }
  },
  components: { TabsButtons, StepTab1, StepTab2, StepTab3, StepTab4 },
  mixins: [data],

}
