
export default {
  name: "TabsButtons",
  props: ['step'],
  methods: {
    change(tab) {
      this.$emit('change', tab);
    }
  }
}
